<template>
	<div id="dialog" class="structured-form">
		<h3>Create New {{ capitalize(labelCategory) }}</h3>
		<p>Make sure to check your spelling.</p>
		<br />

		<FormText
			ref="ipValue"
			v-model="labelValue"
			label="Label"
			:error="valueError"
			maxlength="40"
			@update="resetError('value')"
		/>
		<FormDropdown
			ref="ipDropdown"
			label="Label Type"
			:options="ddOptions"
			v-model="labelType"
			helper="What is this?"
			:error="typeError"
			@helperClick="showHelperDialog"
			@update="resetError('type')"
		/>
		<div class="buttons-wrap">
			<FormButton ref="submit" :level="1" :wait="waitingSubmit" @click="doConfirm" />
			<FormButton ref="cancel" :level="2" value="cancel" @click="doCancel" />
		</div>
	</div>
</template>

<script>
// Vue
import { mapState } from 'pinia'

// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormText from '@/components/FormText'
import FormDropdown from '@/components/FormDropdown'
import FormButton from '@/components/FormButton'

// Other
import { handleApiError } from '@/use/Helpers'
import { capitalize } from '@/use/StringMagic'

export default {
	name: 'DialogLabelCreate',
	components: { FormText, FormDropdown, FormButton },
	setup() {
		const apiStore = useApiStore()
		const labelApi = apiStore.loadApi('label')
		const dialogStore = useDialogStore()
		return { labelApi, dialogStore, capitalize }
	},
	data() {
		return {
			// v-model
			labelValue: null,
			labelType: null,

			// Default values
			defaultParams: {
				submittedValue: '', // What the user has typed.
				labelCategory: 'label', // label / material - This defines which label types are shown in the dropdown.
				// For reference only, default is set in ddOptionsAll.
				defaultType: 'subject', // subject / style / material / substrate - The type that's selected by default in the dialog.
			},

			// Errors
			valueError: '',
			typeError: '',

			// Dropdown
			ddOptionsAll: [
				{
					value: 'subject',
					display: 'Subject',
					selected:
						this.dialogStore.params.defaultType == 'subject' ||
						!this.dialogStore.params.defaultType, // default,
					// selected: true,
				},
				{
					value: 'style',
					display: 'Style',
					selected: this.dialogStore.params.defaultType == 'style',
				},
				{
					value: 'material',
					display: 'Material',
					selected: this.dialogStore.params.defaultType == 'material',
				},
				{
					value: 'substrate',
					display: 'Substrate',
					selected: this.dialogStore.params.defaultType == 'substrate',
				},
			],
		}
	},
	computed: {
		// Pinia state
		...mapState(useDialogStore, ['params', 'doConfirm', 'doCancel', 'waitingSubmit']),

		// Default values
		labelCategory() {
			return this.params.labelCategory || this.defaultParams.labelCategory
		},

		ddOptions() {
			if (this.labelCategory == 'label') {
				// label
				return this.ddOptionsAll
			} else {
				// medium / substrate
				return [this.ddOptionsAll[2], this.ddOptionsAll[3]]
			}
		},
	},
	mounted() {
		this.$refs.ipValue.focus()

		// Set default values.
		this.labelValue = this.params.submittedValue || this.defaultParams.submittedValue

		// Set validation stage
		this.dialogStore.setValidation(this.processForm)
	},
	methods: {
		// Show label types
		showHelperDialog() {
			this.dialogStore.dialogByName('DialogLabelTypes', {
				labelType: this.params.labelType,
				onConfirm: () => {
					this.dialogStore.dialogByName('DialogLabelCreate', {
						submittedValue: this.labelValue,
						labelType: this.params.labelType,
						defaultType: this.labelType,
						onConfirm: this.params.onConfirm,
					})
				},
			})
		},

		// Reset errors on edit
		resetError(fieldName) {
			if (fieldName == 'value') {
				this.valueError = null
			} else if (fieldName == 'type') {
				this.typeError = null
			}
		},

		// This is used as boolean validation stage in dialogStore.
		// If anything goes wrong, the onConfirm will not be executed.
		async processForm() {
			console.log('A')
			if (!this._validate()) return false
			console.log('B')
			const apiResonseOk = await this._sendToApi()
			console.log('C', apiResonseOk)
			if (!apiResonseOk) return false
			console.log('D')
			return true
		},

		// Validate
		_validate() {
			// Make sure we have everything we need.
			// NOTE: Might want to standardize form validation using vee-validate.
			// https://www.vuemastery.com/courses/validating-vue3-forms/why-vee-validate
			if (!this.labelValue || !this.labelType) {
				if (!this.labelType) {
					this.typeError = 'Required'
					this.$refs.ipDropdown.focus()
				}
				if (!this.labelValue) {
					this.valueError = 'Required'
					this.$refs.ipValue.focus()
				}
				return false
			}
			return true
		},

		// API call
		async _sendToApi() {
			// We force lowercase with CSS but still need to convert the actual value.
			const label = this.labelValue.toLowerCase()
			const { status, data, statusText } = await this.labelApi.add(
				encodeURIComponent(label),
				this.labelType
			)

			// Error handling.
			if (status != 200) {
				handleApiError({ status, data, statusText, action: 'creating this label', noFlash: true })
				//
				this.$refs.ipValue.select()

				// Label already exists.
				this.valueError = status == 409 ? 'Label already exists' : statusText
				return false
			}
			return true
		},
	},
}
</script>

<style scoped lang="scss">
#dialog:deep() .wrap {
	width: 100%;
}
#dialog:deep() input {
	text-transform: lowercase;
}
</style>
